import _Loading2 from "element-ui/lib/theme-chalk/loading.css";
import "element-ui/lib/theme-chalk/base.css";
import _Loading from "element-ui/lib/loading";
import axios from "axios";
var loadingInstance;
var service = axios.create();
service.interceptors.request.use(function (config) {
  loadingInstance = _Loading.service({
    fullscreen: true
  });
  return config;
});
service.interceptors.response.use(function (resp) {
  loadingInstance.close();
  return resp;
}, function (error) {
  loadingInstance.close();
  return error;
});
export function Commits(page) {
  return service({
    url: "https://api.github.com/repos/flipped-aurora/gin-vue-admin/commits?page=" + page,
    method: "get"
  });
}
export function Members() {
  return service({
    url: "https://api.github.com/orgs/FLIPPED-AURORA/members",
    method: "get"
  });
}